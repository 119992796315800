<template>
  <div :class="`${isMobile ? '' : 'row w-100 h-100'}`">
    <div class="col-sm left-div" v-if="!isMobile" />
    <div :class="`${isMobile ? 'mt-5 mx-2' : 'col-sm d-flex flex-column justify-content-center'}`">
      <div :class="`${isMobile ? 'w-100' : 'w-75 mx-auto'} p-5 box-white`">
        <div class="center">
          <span class="font-25 font-bold">{{ $t('login') }}</span>
        </div>
        <LoginComponent></LoginComponent>
        <div class="my-10 d-flex justify-content-center vertical-center">
          <span>{{ $t('do_not_have_an_account') }}</span>
          <v-btn text small @click="$router.push(`select_user_type`)">
            <span><u class="font-15 color-purple">{{ $t('register') }}</u></span>
          </v-btn>
        </div>
      </div>
      <TermsComponent></TermsComponent>
    </div>
  </div>
</template>

<style lang="scss">
  @import "@/assets/sass/pages/auth.scss";
</style>

<script>
import LoginComponent from "@/view/pages/auth/LoginComponent.vue";
import TermsComponent from "@/view/pages/auth/TermsComponent.vue";

export default {
  name: 'Login',
  components: {
    LoginComponent,
    TermsComponent
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  }
};
</script>